$blue: #399BDC
$light-blue: #E5F5FF
$blue-hover: #CFECFF
$green: #3D9F1A
$light-green: #E5FDDC
$yellow: #E6AE20
$light-yellow: #FFF7E4
$red: #E71E1E
$light-red: #FFE5E5
$disabled-text: #9CA7AE
$borders: #DBDBDB
$gray: #F6F6F6
$tables: #F8FCFE
$inactive: rgba(7, 34, 52, 0.4)
$hover: #072234

html
    font-family: Mulish, serif !important
    font-size: 18px !important
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale

@media (max-width: 1601px)
    html
        font-size: 16px !important

@media (max-width: 1367px)
    html
        font-size: 14px !important

.d-none
    display: none !important

.d-flex
    display: flex !important

.align-items-center
    align-items: center !important

.align-items-start
    align-items: flex-start !important

.align-items-end
    align-items: flex-end !important

.align-items-baseline
    align-items: baseline !important

.justify-content-center
    justify-content: center !important

.justify-content-between
    justify-content: space-between !important

.flex-wrap
    flex-wrap: wrap

.flex-direction-column
    flex-direction: column !important

.w-100
    width: 100%

.p-0
    padding: 0 !important

.p-t-0
    padding-top: 0 !important

.p-b-0
    padding-bottom: 0 !important

.p-r-0
    padding-right: 0 !important

.p-l-0
    padding-left: 0 !important

.m-0
    margin: 0 !important

.m-b-0
    margin-bottom: 0 !important

.m-b-1
    margin-bottom: 1rem !important

.m-t-0
    margin-top: 0 !important

.m-r-0
    margin-right: 0 !important

.m-l-0
    margin-left: 0 !important

.m-l-1
    margin-left: 1rem !important

.color-primary
    color: $blue !important

.f-w-400
    font-weight: 400 !important

.f-w-500
    font-weight: 500 !important

.f-w-600
    font-weight: 600 !important

.f-w-700
    font-weight: 700 !important

.f-w-800
    font-weight: 800 !important

.f-z-1
    font-size: 1rem !important

.overflow-auto
    overflow: auto

.l-h-2
    line-height: 2 !important

.h-100
    height: 100% !important

.text-center
    text-align: center !important

.text-right
    text-align: right !important

::-webkit-scrollbar
    width: 0.67rem !important
    right: 0.5rem
    bottom: 0.67rem

::-webkit-scrollbar-thumb
    background-color: #CDD3D6
    border-radius: 5.5px

.scrollbar
    top: 0.67rem
    right: 0.5rem
    bottom: 0.67rem
    width: 0.67rem !important
    z-index: 120

.scrollbar_thumb
    background-color: #CDD3D6
    border-radius: 5.5px
    cursor: pointer

@mixin content_block
    background-color: #ffffff
    border: 1px solid $borders
    border-radius: 5px

.block-content
    padding: 2.39rem 2.22rem
    width: 100%
    margin-bottom: 1.66rem
    @include content_block

.block-group
    width: 100%
    margin-bottom: 1.66rem
    overflow: hidden
    @include content_block

    .block-content
        border: none
        border-radius: unset
        margin-bottom: 0

    .block-content:not(:last-child)
        border-bottom: 1px solid $borders

.title
    font-size: 2.22rem
    line-height: 2.77rem
    font-weight: bold

.subtitle
    font-size: 1.5rem
    font-weight: bold

.charts-title
    color: rgba(7, 34, 52, 0.4)
    font-size: 1.3rem
    font-weight: bold
    margin: 0

.table-title
    color: $inactive
    font-size: 1.3rem
    font-weight: 700
    line-height: 1.67rem
    margin: 0

.divider
    border-color: $borders
    margin-top: 0.5rem

.tooltip
    max-width: 19.44rem !important
    margin: 0 !important
    padding: 1.11rem !important
    border: 1px solid $borders
    border-radius: 5px
    background-color: #ffffff !important
    color: #000000 !important
    font-size: 0.9rem !important

    .tooltip_content
        display: flex

    .tooltip_content__icon
        margin-right: 0.56rem

    .tooltip_content__wrap
        display: flex
        align-items: flex-end

    .tooltip_content__details
        margin-left: 0.83rem
        font-size: 0.8rem

    .tooltip_content__details_link
        margin-left: 0.83rem
        font-size: 0.8rem
        color: $disabled-text
        text-decoration: underline

        &:hover
            color: $blue

    &.tooltip_info
        border-width: 2px
        border-color: $blue

        .tooltip_content
            color: $blue

    &.tooltip_success
        border-width: 2px
        border-color: $green

        .tooltip_content
            color: $green

    &.tooltip_warning
        border-width: 2px
        border-color: $yellow

        .tooltip_content
            color: $yellow

    &.tooltip_error
        border-width: 2px
        border-color: $red

        .tooltip_content
            color: $red

.text-decoration-none
    text-decoration: none !important

.text-decoration-underline
    text-decoration: underline !important

.text-transform-initial
    text-transform: initial !important

.link
    font-weight: 500
    font-size: 1rem
    line-height: 1
    color: $blue

.cursor-pointer
    cursor: pointer !important

.border-color
    border-color: $borders !important

.position-relative
    position: relative !important

.position-absolute
    position: absolute !important

.white-space-nowrap
    white-space: nowrap !important

.checkbox:not(.Mui-checked)
    color: $inactive !important

.fade-in
    animation: fadeIn .25s forwards

@keyframes fadeIn
    0%
        opacity: 0
        visibility: hidden
    100%
        opacity: 1
        visibility: visible

.fade-out
    animation: fadeOut .0s forwards

@keyframes fadeOut
    0%
        opacity: 1
        visibility: visible
    100%
        opacity: 0
        visibility: hidden