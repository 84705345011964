@import "./helpers"
@import "./fonts"

#legal_template
    position: relative

    &:before
        background: url("https://unsplash.it/500") center / cover
        background: #f5f5f5
        content: ''
        position: absolute
        left: 0
        right: 0
        top: 0
        bottom: 0
        z-index: -1
        opacity: 0.2

    .header
        font-weight: 400
        color: #000
        padding: 1.39rem 0
        width: 100%
        background-color: #0d47a1

        .logo
            display: block
            width: fit-content
            margin: 0 auto
            line-height: 1

    .content-container
        margin: 0 auto
        width: 44.44rem
        padding: 1.78rem 0 1.78rem

        .content
            padding: 0.33rem 1.78rem 0.67rem