@import "./helpers"
@import "./fonts"

$inner-spacing: 1.94rem
$outer-spacing: 2.22rem
$header-height: 7.22rem
$sidebar-width: calc(100% / 12 * 2.5)
$short-sidebar-width: 4.72rem
$animation-duration: .25s
$animation-function: cubic-bezier(0.4, 0, 0.2, 1)

@mixin animateChange($properties...)
    transition: $properties $animation-duration $animation-function
    -webkit-transition: $properties $animation-duration $animation-function

.scrollbar_horizontal
    right: 1.67rem
    bottom: 0.5rem
    left: calc($sidebar-width + 0.83rem)
    height: 0.67rem !important
    z-index: 120
    @include animateChange(left)

    &.full_page
        left: ($short-sidebar-width + 0.83rem)

#main_template
    padding-left: $sidebar-width
    background-color: $gray
    @include animateChange(padding-left)

    h1, h2, h3, h4, h5, h6, p
        color: $hover

    &.full_page
        padding-left: $short-sidebar-width

    @mixin block
        padding: $inner-spacing

    .block
        @include block

    .block_outer
        padding: $inner-spacing $outer-spacing

    .block_outer__right
        @include block
        padding-right: $outer-spacing

    .block_outer__left
        @include block
        padding-left: $outer-spacing

    .block_force_conversion_switcher
        padding: 1.1rem $inner-spacing 1.1rem $outer-spacing
        border-top: 1px solid $borders
        border-bottom: 1px solid $borders
        @include animateChange(width, opacity)

    #sidebar
        position: fixed
        top: 0
        left: 0
        display: flex
        flex-direction: column
        height: 100vh
        width: $sidebar-width
        background: #fff
        outline: 3px solid $gray
        overflow: hidden
        z-index: 120
        @include animateChange(width)

    .sidebar_header
        display: flex
        justify-content: space-between
        align-items: center
        height: $header-height
        @include animateChange(padding)

        .logo
            line-height: 1
            @include animateChange(width, opacity)

    .sidebar_menu
        height: calc(100% - $header-height - 3.56rem) !important
        overflow-x: hidden
        overflow-y: auto
        border:
            top: 1px solid transparent
            bottom: 1px solid transparent

        &.bordered
            border-color: $borders

    .sidebar_menu__scrollbar
        width: 0.39rem !important

        .sidebar_menu__scrollbar_thumb
            border-radius: 3.5px

    .sidebar_menu__title
        font-weight: bold
        color: $disabled-text
        text-transform: uppercase
        letter-spacing: 0.1em
        padding:
            top: 2.22rem
            bottom: 1.67rem

    .sidebar_menu__item_btn
        display: none
        font-weight: 700
        border-color: $borders
        min-width: auto
        padding: .52rem

    .sidebar_menu__item
        display: flex
        align-items: center
        height: 3.56rem
        color: $hover
        padding: 0 2.22rem 0 0
        text-decoration: none
        border-left: 0.56rem solid transparent
        transition: none

        &.expanded, &:hover
            background-color: #F9FBFC

        &.active
            color: $blue
            font-weight: bold
            border-color: $blue,
            background-color: $light-blue

            .sidebar_menu__item_label
                color: $blue

            &:hover
                background-color: $blue-hover

    .sidebar_menu__item_icon
        display: flex
        align-items: center
        margin-left: calc($outer-spacing - .72rem)
        margin-right: 1.1rem
        @include animateChange(margin)

    .sidebar_menu__btn-wrapper
        display: flex
        grid-gap: 1rem

    .sidebar_menu__icon
        width: 1.333rem
        height: 1.333rem

    .sidebar_menu__item_label
        font-size: 1rem
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
        @include animateChange(width, opacity)

    .sidebar_menu__item_sublink
        display: grid
        grid-template-columns: 1rem 1fr
        grid-auto-flow: column
        grid-gap: 1.16rem
        align-items: center
        color: $hover
        font-size: 1rem
        text-decoration: none
        padding: .61rem 1rem .61rem $outer-spacing
        grid-template-rows: min(2.33rem)

        &.active
            color: $blue
            font-weight: bold

        &:hover
            background-color: $gray

        &.sidebar_menu__item_sublink_mmp
            grid-template-columns: 1rem 1fr 1.2rem

            .sidebar_menu__item_btn
                display: block

        &:last-child
            margin-bottom: unset

    #sidebar.full_page
        width: $short-sidebar-width

        .sidebar_header
            padding: $inner-spacing 0.67rem
            height: calc(calc(calc(3.56rem * 2) + 1rem) + calc($inner-spacing * 2))

            .logo
                width: 0
                opacity: 0
                pointer-events: none

        .sidebar_menu
            height: calc(100% - $header-height - 7.11rem) !important

        .block_force_conversion_switcher,
        .sidebar_menu__title
            display: none

        .sidebar_menu__item
            padding: 0

            &.expanded
                background-color: inherit

            &:hover
                background-color: #F9FBFC

            &.active
                background-color: $light-blue

                &:hover
                    background-color: $blue-hover

        .sidebar_menu__item_icon
            margin-left: calc($inner-spacing - 0.56rem)

        .sidebar_menu__item_label
            width: 0
            opacity: 0

        .sidebar_menu__btn-wrapper
            flex-direction: column

        .notification-btn
            order: 1

    #header
        width: 100%
        height: $header-height
        background: #fff

    #search_input
        height: 3.56rem
        padding: 0 1.39rem

    #content
        min-height: calc(100vh - $header-height) !important
        width: 100%

    .title
        margin: 0 1rem 0 0

    .subtitle
        margin: 1rem 1rem 0 0

    .sticky_header
        background: #fff
        z-index: 100
        padding-top: 1.38rem

    .sticky_header__stickyed
        position: sticky
        top: 0
        box-shadow: 0 1.44rem 2.5rem rgba(7, 34, 52, 0.05)
        border-bottom: 1px solid $borders
        padding:
            top: 1.11rem
            bottom: 1.11rem
        opacity: 0
        visibility: hidden

        .sticky_header__filter
            .MuiOutlinedInput-input
                padding: .5rem 2.5rem .5rem .5rem !important

        .data-action-columns
            .sticky_header__filters.compact
                justify-content: flex-end
                margin-top: .88rem
                .sticky_header__filter
                    flex-basis: 13.19rem

        .date-range-picker-input
            max-width: 31.42rem

            input
                font-size: .9rem
                padding: .66rem .91rem

        .sticky_header__title_row
            margin: 0

        .sticky_header__filters, .sticky_header__filters.compact
            margin-top: 0

        .subtitle
            margin: .75rem .75rem 0 0
            font-size: 1.2rem

        .dropdown-big-text
            .MuiSelect-select
                font-size: 1.5rem

    .sticky_header__padding
        padding-left: 2.22rem

    .sticky_header__title_row
        display: flex
        justify-content: space-between
        align-items: flex-end
        margin-top: .88rem

        .subtitle_icon img
            max-width: 1.67rem

        .subtitle_info
            margin-left: 0.83rem
            font-size: 0.9rem
            font-weight: 500
            color: $disabled-text
            text-transform: uppercase

    .sticky_header__filters
        display: flex
        align-items: center
        margin-top: 1.72rem
        grid-gap: .72rem

        &.compact
            margin-top: 0.83rem

            .sticky_header__filter
                flex-basis: 50%

        &.creative_pages
            .sticky_header__filter
                flex-basis: 100%

    .sticky_header__filter
        width: -webkit-fill-available
        width: -moz-available
        flex-basis: 9.45rem

    .content_block
        margin-bottom: 1.67rem

        &:last-child
            margin-bottom: 0

    .breadcrumbs
        font-size: 0.9rem !important
        color: $disabled-text !important

    .breadcrumb_link
        color: $disabled-text
        text-decoration: none

        &:hover
            color: $blue
            text-decoration: underline

    .breadcrumb_link__active
        color: black

    .button
        margin-right: 1.11rem

        &:last-child
            margin-right: 0

    .icon_button
        height: 3.56rem
        width: 3.56rem
        box-shadow: none !important
        min-width: auto

    .second_subtitle
        color: $blue
        font-size: 1.3rem
        font-weight: bold

    .info_panel
        @include content_block
        display: flex
        flex-direction: column
        justify-content: space-between

    .info_panel__title
        margin-bottom: 0.56rem
        white-space: nowrap

    .info_panel__title_tooltip
        margin-left: 0.56rem

    .info_panel__content
        display: flex
        justify-content: space-between
        align-items: flex-end
        line-height: 1

    .info_panel__value
        min-width: calc(100% - 8.33rem)
        font-size: 2.2rem
        font-weight: 900
        margin: .56rem .5rem 0 0
        color: $hover

    .info_panel__delta
        display: flex
        justify-content: flex-end
        align-items: center
        flex-wrap: nowrap
        min-width: 5.56rem
        font-size: 1.1rem
        margin: 0.56rem 0 0 auto

    .info_bar
        display: flex

        .info_panel
            width: 100%
            margin-right: 1.67rem

            &:last-child
                margin-right: 0

    .accordion_block
        @include content_block

    .accordion_block__title
        display: flex
        align-items: center
        margin: 0
        color: $blue

    .campaign_info_table__section
        @include block
        padding-bottom: 3.33rem
        border-right: 1px solid $borders

        &:last-child
            border-right: none

    .campaign_info_table__section__title_row
        display: flex
        justify-content: space-between
        align-items: center
        margin-bottom: 2.22rem

    .tooltip_label
        color: $disabled-text
        text-decoration: underline

        &.tooltip_label_info
            color: $blue

        &.tooltip_label_success
            color: $green

        &.tooltip_label_warning
            color: $yellow

        &.tooltip_label_error
            color: $red

    .tab_block
        @include content_block

    .tab_block__tabs
        border-bottom: 1px solid $borders

    .tab_block__tab
        min-width: 12.78rem
        text-transform: inherit
        padding: 1.5rem 0.56rem
        font-size: 1rem
        font-weight: 600

    .tab_block__panel
        padding: 3.33rem 2.22rem

    .recs_conclusion
        margin:
            top: 1.39rem !important
            bottom: 2.78rem !important

    .recs_item
        padding: 2.22rem 0
        border-bottom: 1px solid $borders

        &:first-child
            padding-top: 0

        &:last-child
            padding-bottom: 0
            border-bottom: none

    .recs_item__description
        margin-left: 2.22rem
        font-weight: 500
        width: 100%

    .recs_item__description_title
        margin:
            top: 0
            bottom: 2.22rem

    .recs_item__description_no_data
        color: $disabled-text

    .recs_item__description_item
        display: flex
        justify-content: space-between
        align-items: center
        margin-top: 0.68rem
        padding: 0.77rem

        &:first-child
            margin-top: 0

    .recs_item__description_new-item
        background-color: $light-blue

    .recs_item__description_hidden-item
        filter: opacity(0.5)

    .recs_item__description_item_icon
        margin-right: 0.78rem

    .recs_item__description_assets_block
        padding:
            top: 0.56rem
            right: 0.77rem
            bottom: 0.56rem
            left: 3.89rem

    .recs_item__description_assets
        padding: 1.11rem 0 0 1.11rem
        margin: 0

    .recs_item__description_asset
        margin-bottom: 0.56rem

        &:last-child
            margin-bottom: 0

        a
            color: $blue

    .percent
        height: fit-content

    .percent_circle
        width: fit-content
        text-align: center

    .percent_borders
        height: 6.39rem
        width: 6.39rem
        border: 0.72rem solid transparent
        border-radius: 50%

    .percent_value
        font-size: 1.66rem
        font-weight: bold
        z-index: 1

    .percent_description
        max-width: 6.39rem
        margin-top: 1.33rem
        font-weight: 500

    @mixin percent_circle__clock__borders_after
        content: ""
        display: block
        height: 0.67rem
        width: 0.72rem
        border-radius: 50%

    .percent_borders:after
        @include percent_circle__clock__borders_after
        transform: rotate(-45deg) translate(3%, 25%)

    .percent_background
        height: 6.39rem
        width: 6.39rem
        border-radius: 50%

        &.hider
            top: -0.8px
            left: -0.8px

            &:after
                @include percent_circle__clock__borders_after
                transform: rotate(90deg) translate(5%, -435%)

    .percent_block__item_description
        max-width: 6.39rem
        margin-top: 1.33rem
        font-size: 1rem
        font-weight: 500

    .percent_block__divider
        padding: 0 0.89rem
        font-size: 2.7rem
        color: $disabled-text

    .arrow_metric__legend_icon
        position: absolute
        top: 0.56rem
        right: -1.67rem

    .arrow_metric__title
        font-size: 1.66rem
        font-weight: bold
        text-align: center
        margin-bottom: 1.67rem

    .arrow_metric__scale
        position: relative
        height: 6.67rem
        width: 13.33rem

        &:before
            content: ""
            position: absolute
            display: block
            height: 100%
            width: 100%
            bottom: -100%
            background-color: #fff
            z-index: 1

    .arrow_metric__scale_item
        position: absolute
        height: 13.33rem
        width: 100%
        border: 1.67rem solid transparent
        border-radius: 50%

    .arrow_metric__arrow
        position: absolute
        bottom: -1.94rem
        height: 4.44rem
        width: 4.44rem
        z-index: 1

        &:before
            content: ""
            position: absolute
            top: -2.22rem
            left: 1.78rem
            height: 0
            width: 0
            background-color: transparent
            border: 0.44rem solid transparent
            border-bottom: 1.94rem solid
            z-index: -1

        &:after
            content: ""
            display: block
            height: 100%
            width: 100%
            background-color: #fff
            border-radius: 50%

    .arrow_metric__value
        position: absolute
        bottom: 0
        width: fit-content
        font-size: 1.85rem
        font-weight: bold
        line-height: 1
        z-index: 1

        &.no_data
            font-size: 1rem
            font-weight: 400

    .campaign_chart
        margin-bottom: 1.67rem

        &:last-child
            margin-bottom: 0

    .campaign_chart__title
        font-size: 1rem
        font-weight: 500

    .dropdown-big-text
        .MuiSelect-select:focus
            background-color: transparent

    .table-icon
        & img
            width: 1.11rem

.arrow_metric__legend
    .legend_row
        display: flex
        align-items: baseline
        margin-bottom: 5px

    .legend_row_item
        margin-right: 0.83rem

        &:last-of-type
            margin-right: 0

    .legend_color
        display: inline-block
        height: 0.56rem
        width: 0.56rem
        border-radius: 50%

    .legend_data
        font-size: 0.9rem
        font-weight: normal
        width: 4.72rem

    .legend_description
        display: inline-block
        max-width: 9.72rem
        font-size: 0.9rem
        color: $disabled-text

.no-data-placeholder
    width: 19.22rem
    height: 19.22rem
    border-radius: 50%
    border: 4.28rem solid $borders
    margin: 0 auto

.chart-container
    .apexcharts-svg
        overflow: initial

    .apexcharts-xaxis-title-text,
    .apexcharts-yaxis-title-text
        text-transform: capitalize

    .apexcharts-text
        font-size: 0.9rem !important

    .apexcharts-legend-text
        font-size: 1rem !important

    .apexcharts-tooltip
        z-index: 120
        box-shadow: 0 1.44rem 2.5rem rgba(7, 34, 52, 0.05) !important
        border: 1px solid $borders !important
        min-width: 17.83rem !important

    .apexcharts-tooltip-title
        font-size: 0.9rem !important
        font-weight: 700 !important
        padding: 0.67rem 1.22rem !important
        background: $gray !important
        border-color: $borders !important
        margin-bottom: 1.33rem !important

    .apexcharts-tooltip-marker
        margin-right: 0.72rem !important
        width: 0.72rem !important
        height: 0.72rem !important
        display: flex
        align-items: center
        justify-content: center

    .apexcharts-tooltip-text
        font-size: 0.9rem !important
        font-weight: 500 !important

    .apexcharts-tooltip-y-group
        padding: 0 !important

    .apexcharts-tooltip-series-group
        padding: 0 1.22rem 1.11rem !important

.connection-dialog__content
    min-width: 35.58rem

.connection-dialog__network-container
    display: flex
    justify-content: center
    flex-wrap: wrap
    grid-gap: .72rem

.connection-dialog__title
    font-size: 1.66rem
    line-height: 1.72rem
    font-weight: bold

.connection-dialog__subtitle
    padding-top: .83rem
    padding-bottom: 1.16rem
    color: $inactive
    font-size: 1rem
    font-weight: 500

.connection-dialog__network-btn
    padding: 1.22rem !important
    border: 1px solid $borders !important
    border-radius: 3px !important
    color: unset !important
    font-size: 1rem !important
    text-transform: unset !important
    align-items: stretch
    flex-basis: 11.38rem
    &.active
        border-color: $blue !important
        color: $blue !important

        svg
            fill: $blue

.connection-dialog__network-disabled
    opacity: 50%

.connection-dialog__coming-soon
    color: $blue
    font-style: italic
    font-size: .75em
    line-height: .8rem

.dropdown-paper
    margin-top: 0.72rem
    border-radius: 5px !important
    border: 1px solid $borders
    box-shadow: none !important
    max-height: 19.44rem !important
    &.disable-offset-top
        ul
            padding-top: 0
    ul
        padding: .72rem 0

    &.dropdown-paper-mini
        ul
            max-height: 5.66rem
            padding: .47rem 0
            li
                font-size: .83rem
                padding: 0.11rem 1.22rem

.dropdown-paper-primary
    border-color: $blue

.value-picker__paper
    border-radius: 20px !important
    ul
        padding: .86rem 0

.value-picker__content
    background-color: $green !important
    color: #fff !important
    font-size: .83rem !important
    font-weight: 400 !important
    line-height: 173% !important
    padding: .33rem 3.5rem .33rem 1rem !important
    border-radius: 50px !important
    &:focus
        background-color: $green !important
        border-radius: 50px !important
    &.without-icon
        padding: .33rem 2rem !important

.value-picker__icon
    right: .8rem !important
    top: calc(50% - .45em) !important
    color: #fff !important
    font-size: 1.33rem !important

.text-field__label
    font-size: 1rem
    font-weight: 500
    line-height: 2
    color: $inactive
    margin-bottom: .27rem

.text-field__hint
    font-weight: 500
    font-size: .83rem
    line-height: 1.44rem
    color: $inactive
    margin-bottom: .27rem

.app-info__container
    display: grid
    grid-gap: .83rem
    grid-template-columns: 1.5rem 1.55rem 1fr
    align-items: center

.table-column-title__title
    color: rgba(0, 17, 34, 0.95)
    margin-bottom: .33rem

.table-column-title__subtitle
    color: rgba(0, 17, 34, 0.66)
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

.table-column-title__title,
.table-column-title__subtitle
    font-size: .83rem

.app-info__icon
    width: 1.55rem
    height: 1.55rem
    border-radius: 3px
    font-size: .5rem
    background-color: $inactive

.dropdown-menu__list
    padding-top: .44rem !important
    padding-bottom: .44rem !important

.dropdown-menu__item
    font-size: .83rem !important
    font-weight: 500 !important
    width: 100%
    & > div, span
        padding: .27rem 1.22rem

.round-btn
    color: #fff !important
    border-radius: 30px !important
    font-size: .83rem !important

.notification-type
    display: grid
    grid-template-columns: 1.55rem 1fr
    grid-gap: 1rem

.app-mmp-status
    display: grid
    align-items: center
    grid-template-columns: 2rem 11rem 2rem
    grid-gap: .5rem

.app-mmp-target
    display: grid
    align-items: center
    grid-template-columns: 8.05rem 2.44rem
    grid-gap: .9rem

.tariff-wrapper
    display: flex
    grid-gap: 1em
    justify-content: center

.tariff-item
    display: flex
    flex-direction: column
    border: 2px solid $borders
    border-radius: 10px
    padding: 32px 24px
    width: 16.66rem
    &:hover
        border-color: $blue

.tariff-item__title
    font-weight: bold
    font-size: 1.9rem
    text-align: center

.tariff-item__cost
    font-size: 1.33rem
    font-style: italic
    font-weight: 600
    margin: 1.5rem 0
    text-align: center

.tariff-item__cost-period
    font-size: .8em

.tariff-item__benefits
    gap: 20px 0
    list-style-type: none
    margin: 2rem 0 0
    padding: 0

.tariff-item__benefit-item
    display: flex
    gap: 0 10px
    font-size: .83rem

    &::before
        display: block
        content: ""
        width: .66rem
        height: .66rem
        min-width: .66rem
        background-color: $blue
        border-radius: 2px
        margin-top: 5px

.tariff-actions
    text-align: right
    margin-top: 1rem