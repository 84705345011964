@import "./helpers"
@import "./fonts"

$header-height: 6.11rem

#auth_template
    #header
        height: $header-height
        width: 100%
        padding: 0 2.61rem
        background: #ffffff
        border-bottom: 1px solid $borders
        z-index: 100

    .logo
        line-height: 1
        padding: 2.22rem 1.85rem

    .header__nav_item
        text-decoration: none
        color: black
        padding: 2.22rem 1.85rem

        &:hover
            background-color: #F9FBFC

        strong
            color: $blue

    .ellipse
        position: absolute
        height: 30vh
        width: 30vh
        background-color: $blue
        filter: blur(25vh)
        border-radius: 50%
        z-index: -1

        &.top
            top: 20vh
            right: 50vh

        &.bot
            bottom: 20vh
            left: 50vh

    #content
        min-height: 100vh
        width: 100%
        padding:
            top: calc($header-height + 2.78rem)
            bottom: 2.78rem
        background-image: url('../../public/static/auth/background.svg')
        background-size: cover
        background-position: center
        background-repeat: no-repeat

        .window
            width: min-content
            min-width: 32.22rem
            padding: 2.22rem 3.06rem 3.61rem
            @include content_block

            .title
                font-size: 1.67rem
                text-align: center
                margin:
                    top: 2.22rem
                    bottom: 1.56rem

            .subtitle
                font-size: 1rem
                font-weight: 500
                line-height: 2
                text-align: center
                letter-spacing: 0.15px
                color: $disabled-text

            .input_group
                padding-top: 1.56rem

                .input
                    margin-bottom: 1.11rem

            .choose_block
                margin-bottom: 1.11rem

                a
                    text-decoration: none
                    font-weight: 600
                    color: $blue

            .choose_block__checkbox a
                text-decoration: underline

            .with_helper
                margin-bottom: 0 !important

            .actions
                display: flex
                margin-bottom: 0.83rem

                .button
                    width: 100%
                    font-size: 1.25rem
                    font-weight: bold
                    padding: 0.83rem
                    text-transform: unset
                    box-shadow: none
                    border-width: 2px

                    &.group_button
                        min-width: 13.11rem
                        font-size: 1rem
                        font-weight: 600
                        line-height: 1
                        margin-right: 0.83rem

                        &:last-child
                            margin-right: 0

            .alternative_offer
                font-weight: 500
                text-align: center
                color: $disabled-text
                margin-top: 2.22rem
                padding: 1.85rem 0 0.83rem
                border-top: 1px solid $borders

                a
                    font-weight: bold
                    color: $blue

            .alternative_action
                text-align: center
                margin-top: 1.94rem

                .button
                    font-size: 1rem
                    font-weight: 600
                    line-height: 1
                    width: auto
                    text-transform: unset
                    text-decoration: underline
                    color: $disabled-text

            .network_account
                min-width: 43.06rem
                padding: 1.39rem
                margin-bottom: 0.83rem
                border: 1px solid $borders
                border-radius: 3px
                font-size: 1rem
                font-weight: 500

                &:last-child
                    margin-bottom: 2.22rem

            .network_account__network
                margin-right: 3.33rem

            .network_account__network_icon
                margin-right: 0.83rem

            .network_account__name
                margin-right: 3.61rem

            .network_account__action
                margin-left: auto